<template>
    <div class="avatarkit-footer-black">
      <div class="avatarkit-container-component w-container">
        <div class="footer-box">
            <div class="footer-box-item">
              <div  class="tit">{{ $t("message.msg63") }}</div>
              <a :href="platformData.platform_kf_url" class="f-section">{{ $t("message.msg64") }}</a>
              <a :href="platformData.buyer_app_down_url" class="f-section">{{ $t("message.msg65") }}</a>
              <a :href="platformData.seller_app_down_url" class="f-section">{{ $t("message.msg66") }}</a>
            </div>
            <div class="footer-box-item">
              <div class="tit">{{ $t("message.msg67") }}</div>
              <div @click="goYs()" class="f-section">{{ $t("message.msg68") }}</div>
              <div @click="goTh()" class="f-section">{{ $t("message.msg69") }}</div>
              <div @click="goMj()" class="f-section">{{ $t("message.msg70") }}</div>
            </div>
            <div class="footer-box-item">
              <div class="tit">{{ $t("message.msg71") }}</div>
              <a :href="jumpUrl" class="f-section">{{ $t("message.msg72") }}</a>
              <a :href="platformData.shop_wap_url" class="f-section">{{ $t("message.msg73") }}</a>
              <div  class="f-section">{{ $t("message.msg74") }}</div>
            </div>
            <div class="footer-box-item">
              <div class="tit">{{ $t("message.msg75") }}</div>
              <a class="f-section">{{ $t("message.msg75") }}</a>
              <a class="f-section">{{ $t("message.msg76") }}</a>
              <div class="f-section">{{ $t("message.msg77") }}</div>
            </div>
        </div>
      </div>
      <div class="avatarkit-container-component">
        <div class="avatarkit-footer-wrapper-center">
          <div class="avatarkit-footer-block-center">
            <!-- <a @click="goIndex()" class="avatarkit-footer-brand w-inline-block"
              ><img
                src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f0078d6c9877958e3e5f24_%E7%BC%96%E7%BB%84%208.png"
                loading="lazy"
                width="146"
                alt=""
            /></a> -->

            <!-- <a @click="goIndex()" class="avatarkit-footer-brand w-inline-block"
              ><img
                src="@/assets/images/logo.png"
                loading="lazy"
                width="146"
                alt=""
            /></a> -->
            <div class="avatarkit-text-footer-wrapper"></div>
          </div>
        </div>
      </div>
      <div class="copyright">Copyright © 2015-2023 NA-Walmart</div>
    </div>
</template>
<script>
export default {
    name: 'foot',
    props: {
        platformData: {},
        jumpUrl: {
            type: String
        }
    },
    methods: {
        goYs() {
            this.$router.push('/yszc')
        },
        goTh() {
            this.$router.push('/thzc')
        },
        goMj() {
            this.$router.push('/mjzc')
        },
        goIndex() {
            this.$router.push('/')
        },
    }
}
</script>
<style>
.footer-box{
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.copyright{
  color: #fff;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}
.f-section{
  font-size: 12px;
  color: #fff;
  font-weight: 300;
  margin-top: 15px;
  cursor: pointer;
  display: block;
}
.footer-box-item{
  margin-top: 20px;
}
.tit{
  color: #fd4924;
  font-size: 16px;
  font-weight: 700;
}
@media screen and (max-width: 479px) {
  .footer-box-item{
    width: 45%;
  }
}
</style>