export default {
  message: {
    "msg1": "Born in 2015, it is a secure and fast online shopping platform tailored for the Southeast Asian region. The company has planned a highly advanced and mutually beneficial business model.",
    "msg2": "Southeast Asia's preferred online shopping platform",
    "msg3": "Enter the mall",
    "msg4": "Advanced business strategy",
    "msg5": "Adopting a mutually beneficial model and creating prosperity together.",
    "msg6": "Retail giants in Southeast Asia",
    "msg7": "Market leader, winning the trust of consumers.",
    "msg8": "Convergence of global and local brands",
    "msg9": "We provide you with a one-stop shopping experience both internationally and locally.",
    "msg10": "about",
    "msg11": "The headquarters is located in Singapore.",
    "msg12": "It is now a leading online shopping mall in the Southeast Asian market, providing one-stop shopping services for numerous international and local brands.",
    "msg13": "Our market",
    "msg14": "At present, the main markets are Singapore, Malaysia, Indonesia, Thailand, Vietnam and Taiwan, China",
    "msg15": "Starting from 2023, we will focus on developing the Japanese market.",
    "msg16": "More regions, stay tuned",
    "msg17": "Our mission",
    "msg18": "It is to provide a global trading platform where anyone can trade any item.",
    "msg19": "Privacy Policy",
    "msg20": "brief introduction",
    "msg21": "Welcome to the NA-Walmart store website (individually and collectively referred to as \"NA-Walmart\", \"us\", \"ours\", or \"ours\"). NA-Walmart conscientiously fulfills its responsibilities under applicable privacy laws and regulations (the \"Privacy Law\") and promises to respect the privacy rights and concerns of all users of the NA-Walmart website and mobile applications (the \"Platform\") (we collectively refer to the Platform and the services we provide on the Platform as the \"Services\"). Users refer to users who register accounts with us for the purpose of using the service, including buyers and sellers (individually and collectively referred to as \"users\", \"you\" or \"your\"). We recognize the importance of the personal data you have entrusted us with, and believe that we have a responsibility to properly manage, protect, and process your personal data. This privacy policy (\"Privacy Policy\" or \"Policy\") aims to help you understand how we collect, use, disclose, and process personal data that you provide to us and that we possess about you, whether it is now or in the future, and to assist you in making informed decisions before providing us with any of your personal data.",
    "msg22": "Personal data refers to data about individuals that can be identified from this data or other information that the organization has or may have access to, regardless of whether it is genuine or not. Common examples of personal data include names, ID numbers, and contact information.",
    "msg23": "By using the service, registering an account with us, accessing our platform, or accessing the service, you acknowledge and agree that you accept the practices, requirements, and policies outlined in this privacy policy. You hereby agree that we will collect, use, disclose, and process your personal data as described here. If you do not agree to process your personal data as described in this privacy policy, please do not use our services or access our platform. If we change our privacy policy, we will notify you by publishing these changes or revised privacy policies on our platform. We reserve the right to modify this privacy policy at any time. To the maximum extent permitted by applicable law, you may use the services on the platform, including placing orders.",
    "msg24": "This policy applies in conjunction with other notices, contract terms, and consent terms applicable to our collection, storage, use, disclosure, and processing of your personal data. Unless otherwise explicitly stated, we do not intend to override these notices or terms",
    "msg25": "Unless otherwise specified, this policy applies to both buyers and sellers who use this service.",
    "msg26": "When does NA-Walmart collect personal data?",
    "msg27": "We will/may collect personal data about you:",
    "msg28": "When you visit and access our website;",
    "msg29": "When you submit any job application or form, including but not limited to the application form or other forms related to any of our job postings;",
    "msg30": "When you interact with us through phone calls (which may be recorded), letters, faxes, face-to-face meetings, interviews, social media platforms, emails, or through websites. It includes but is not limited to cookies that we may deploy when you visit and access our website;",
    "msg31": "We may collect personal data about you from you, our affiliates, third parties, and other sources, including but not limited to business partners (such as logistics or payment service providers), credit or rating agencies, marketing service providers or partners, referrals or loyalty programs, other users of our services, or publicly available or government data sources.",
    "msg32": "In some cases, you may provide us with personal data about other people, such as your family or friends or people on your contact list. If you provide us with their personal data, you declare and guarantee that you have obtained their consent to process their personal data in accordance with this policy.",
    "msg33": "What personal data does NA-Walmart collect?",
    "msg34": "The personal data that NA-Walmart may collect includes but is not limited to:",
    "msg35": "Name;",
    "msg36": "Email address;",
    "msg37": "Date of birth;",
    "msg38": "Home address;",
    "msg39": "Telephone number",
    "msg40": "You agree not to submit any inaccurate or misleading information to us, and you agree to notify us of any inaccuracies or changes in such information. We reserve the right to request further documents to verify any information you provide.",
    "msg41": "return policy ",
    "msg42": "I changed my mind",
    "msg43": "You can return the item within 15 days after pickup or delivery.",
    "msg44": "Your item must be:",
    "msg45": "Unused and with all original components;",
    "msg46": "In the original packaging (with labels if applicable);",
    "msg47": "Product Support",
    "msg48": "In most cases, if you have any item issues, you can comfortably check our product support website at home to see if we can solve the problem. There, you can access frequently asked questions about products and our company, transportation and pickup, returns and refunds.",
    "msg49": "There is a problem with my goods",
    "msg50": "If you still need a return (within the first 15 days), we will provide you with an exchange, replacement, or refund.",
    "msg51": "After this period, as long as the product is still under warranty, we will use our professional repair agent to arrange repairs, or if repairs are not possible, replace or replace your product. The fastest way to return goods is through our global warehousing centers located in 112 countries/regions. We can accept most items except for large items, even if they were originally delivered to our doorstep. Find the nearest store to you. If you want to return a large item, please scroll down to start a real-time chat or go to our help hotline link.",
    "msg52": "Delivery and pickup",
    "msg53": "Where do you deliver the goods?",
    "msg54": "To learn if we have shipped to your region, please enter your postal code on the item you are purchasing. The products are shipped directly from our suppliers, and our global warehouses are located in 112 countries/regions.",
    "msg55": "Where can I get it?",
    "msg56": "We operate 24/7 at our collection points in NA-Walmart stores and global warehouses.",
    "msg57": "For items that can be used for fast track delivery, please choose from multiple time periods based on your location. The order will be delivered to your doorstep and stored in a safe and reliable place.",
    "msg58": "Frequently Asked Questions",
    "msg59": "What if I order both large and small items at the same time?",
    "msg60": "The fastest way to deliver them to you is to ship them separately. Don't worry; You only need to pay the shipping fee once. Please check your order confirmation email for more information.",
    "msg61": "What if I'm not at home?",
    "msg62": "Small items will be left in a safe and reliable place, out of sight, or handed over to your neighbors at your request. Large or valuable items require photos or signatures and cannot be left behind, so someone must collect them at home. If you are unable to arrive, please contact us through the help hotline so that we can reschedule the delivery time and choose the most suitable date and time period for you!",
    "msg63": "customer service",
    "msg64": "Online Service ",
    "msg65": "App download (buyer side)",
    "msg66": "App download (seller side)",
    "msg67": "Returns and Exchanges",
    "msg68": "Privacy Policy",
    "msg69": "return policy ",
    "msg70": "Delivery and pickup",
    "msg71": "User Center",
    "msg72": "User registration",
    "msg73": "Merchant login",
    "msg74": "User Agreement",
    "msg75": "About us",
    "msg76": "Recruitment of Talents",
    "msg77": "Modern Slave Declaration"
  }
};