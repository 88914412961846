var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "avatarkit-nav-component w-nav",
          attrs: {
            "data-animation": "default",
            "data-collapse": "medium",
            "data-duration": "400",
            "data-easing": "ease",
            "data-easing2": "ease",
            role: "banner"
          }
        },
        [
          _c("div", { staticClass: "avatarkit-container-component" }, [
            _c("div", { staticClass: "avatar-navbar-global-padding-wrapper" }, [
              _vm._m(0),
              _c("div", { staticClass: "col-links" }, [
                _c(
                  "div",
                  {
                    staticClass: "dropdown lang",
                    on: {
                      click: function($event) {
                        _vm.showLang = !_vm.showLang
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-toggle ignore",
                        attrs: {
                          href: "javascript:void(0)",
                          id: "yuyan",
                          "data-toggle": "dropdown",
                          role: "button",
                          "aria-haspopup": "true",
                          "aria-expanded": "false"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "country-img",
                          attrs: { src: _vm.langImg, alt: "" }
                        }),
                        _c("span", { attrs: { id: "yuyanText" } }, [
                          _vm._v(_vm._s(_vm.langName))
                        ]),
                        _c("i", { staticClass: "caret" })
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showLang,
                            expression: "showLang"
                          }
                        ],
                        staticClass: "dropdown-menu ignore",
                        attrs: { id: "dropdown-menu" }
                      },
                      _vm._l(_vm.langList, function(item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "country-box",
                            on: {
                              click: function($event) {
                                return _vm.changeLang(item)
                              }
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "country-img",
                              attrs: { src: item.country_img, alt: "" }
                            }),
                            _c("a", [_vm._v(_vm._s(item.language))])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              ])
            ])
          ])
        ]
      ),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "avatarkit-padding-global-components-bck-green" },
        [
          _c("div", { staticClass: "avatarkit-container-component" }, [
            _c("div", { staticClass: "avatarkit-grid-two-row" }, [
              _c("div", { staticClass: "avatarkit-width-small-card" }, [
                _c("div", { staticClass: "avatarkit-mg-32px-bottom" }, [
                  _c("h1", { staticClass: "avatarkit-large-text-white" }, [
                    _c("span", { staticClass: "ignore" }, [
                      _vm._v("NA-Walmart")
                    ]),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("message.msg2")) + "\n            ")
                  ])
                ]),
                _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
                  _c("p", { staticClass: "avatarkit-left-white-text-copy" }, [
                    _c("span", { staticClass: "ignore" }, [
                      _vm._v("NA-Walmart")
                    ]),
                    _vm._v(_vm._s(_vm.$t("message.msg1")) + "\n            ")
                  ])
                ]),
                _c("div", { staticClass: "avatarkit-mg-40px-top" }, [
                  _c(
                    "a",
                    {
                      staticClass: "avatarkit-button-primary-white w-button",
                      attrs: { href: _vm.jumpUrl }
                    },
                    [_vm._v(_vm._s(_vm.$t("message.msg3")))]
                  )
                ])
              ]),
              _vm._m(2)
            ])
          ])
        ]
      ),
      _c("div", { staticClass: "avatarkit-padding-global-components _2" }, [
        _c("div", { staticClass: "avatarkit-container-component" }, [
          _c("div", { staticClass: "w-layout-grid avatarkit-services-grid" }, [
            _c("div", { staticClass: "avatarkit-feature-card-transparent" }, [
              _vm._m(3),
              _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
                _c("h5", { staticClass: "avatarkit-service-title" }, [
                  _vm._v(_vm._s(_vm.$t("message.msg4")))
                ])
              ]),
              _c("p", { staticClass: "avatarkit-center-text" }, [
                _vm._v(_vm._s(_vm.$t("message.msg5")))
              ])
            ]),
            _c("div", { staticClass: "avatarkit-feature-card-transparent" }, [
              _vm._m(4),
              _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
                _c("h5", { staticClass: "avatarkit-service-title" }, [
                  _vm._v(_vm._s(_vm.$t("message.msg6")))
                ])
              ]),
              _c("p", { staticClass: "avatarkit-center-text" }, [
                _vm._v(_vm._s(_vm.$t("message.msg7")))
              ])
            ]),
            _c("div", { staticClass: "avatarkit-feature-card-transparent" }, [
              _vm._m(5),
              _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
                _c("h5", { staticClass: "avatarkit-service-title" }, [
                  _vm._v(_vm._s(_vm.$t("message.msg8")))
                ])
              ]),
              _c("p", { staticClass: "avatarkit-center-text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("message.msg9")) +
                    "\n          "
                )
              ])
            ])
          ])
        ])
      ]),
      _c("section", { staticClass: "hero-stack" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "hero-wrapper-two" }, [
            _c("h1", { staticClass: "heading" }, [
              _vm._v("\n          " + _vm._s(_vm.$t("message.msg10"))),
              _c("span", { staticClass: "ignore" }, [_vm._v("NA-Walmart")])
            ]),
            _c("p", { staticClass: "margin-bottom-24px" }, [
              _c("span", { staticClass: "ignore" }, [_vm._v("NA-Walmart")]),
              _vm._v(_vm._s(_vm.$t("message.msg11")) + "\n          "),
              _c("span", { staticClass: "ignore" }, [_vm._v("NA-Walmart")]),
              _vm._v(_vm._s(_vm.$t("message.msg12")) + "\n        ")
            ]),
            _c("img", {
              staticClass: "hero-image shadow-two",
              attrs: {
                src:
                  "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f08d965a2be2cc299972a1_64f012e9886ecb205028b6c5_online-shopping%20(1)%402x%20(1).png",
                loading: "lazy",
                alt: ""
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "avatarkit-padding-global-components _5" }, [
        _c("div", { staticClass: "avatarkit-container-component" }, [
          _c("div", { staticClass: "avatarkit-grid-two-row" }, [
            _c("div", { staticClass: "avatarkit-content-width-left" }, [
              _c("div", { staticClass: "avatarkit-mg-32px-bottom" }, [
                _c("h3", { staticClass: "avatarkit-large-header" }, [
                  _vm._v(_vm._s(_vm.$t("message.msg13")))
                ])
              ]),
              _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
                _c("p", { staticClass: "avatarkit-left-text-copy" }, [
                  _vm._v("\n              " + _vm._s(_vm.$t("message.msg14"))),
                  _c("br"),
                  _vm._v(_vm._s(_vm.$t("message.msg15")) + "\n            ")
                ])
              ]),
              _c("div", { staticClass: "avatarkit-mg-40px-top" }, [
                _c(
                  "a",
                  {
                    staticClass: "avatarkit-button-primary-2 w-button",
                    attrs: { href: _vm.jumpUrl }
                  },
                  [_vm._v(_vm._s(_vm.$t("message.msg3")))]
                )
              ])
            ]),
            _c("div", { staticClass: "avatarkit-width-small-card" }, [
              _c(
                "div",
                { staticClass: "w-layout-grid avatarkit-logo-grid-right" },
                [
                  _vm._m(6),
                  _vm._m(7),
                  _vm._m(8),
                  _vm._m(9),
                  _vm._m(10),
                  _vm._m(11),
                  _vm._m(12),
                  _c(
                    "p",
                    {
                      staticClass: "paragraph",
                      attrs: {
                        id:
                          "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc0c-0d235eea"
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("message.msg16")) +
                          "...\n            "
                      )
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "avatarkit-padding-global-components _1" }, [
        _c("div", { staticClass: "avatarkit-container-component" }, [
          _c("div", { staticClass: "avatarkit-container-component" }, [
            _c("div", { staticClass: "avatarkit-cta-info" }, [
              _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
                _c("h3", { staticClass: "avatarkit-large-header" }, [
                  _c("span", { staticClass: "ignore" }, [_vm._v("NA-Walmart")]),
                  _vm._v(_vm._s(_vm.$t("message.msg17")) + "\n            ")
                ]),
                _c("p", { staticClass: "avatarkit-left-text-copy" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.$t("message.msg18")) +
                      "\n            "
                  )
                ])
              ]),
              _c("div", { staticClass: "avatarkit-mg-40px-top" }, [
                _c(
                  "a",
                  {
                    staticClass: "avatarkit-button-primary-3 w-button",
                    attrs: { href: _vm.jumpUrl }
                  },
                  [_vm._v(_vm._s(_vm.$t("message.msg3")))]
                )
              ])
            ])
          ])
        ])
      ]),
      _c("foot", {
        attrs: { jumpUrl: _vm.jumpUrl, platformData: _vm.platformData }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "avatarkit-navbar-brand w-nav-brand",
        attrs: { href: "#" }
      },
      [
        _c("img", {
          attrs: {
            src: require("../assets/images/logo.png"),
            loading: "lazy",
            width: "63",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-nav-component-2 w-nav",
        attrs: {
          "data-animation": "default",
          "data-collapse": "medium",
          "data-duration": "400",
          "data-easing": "ease",
          "data-easing2": "ease",
          role: "banner"
        }
      },
      [_c("div", { staticClass: "avatarkit-container-component-2" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatarkit-content-width-left" }, [
      _c("img", {
        attrs: {
          src:
            "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f08ce6c7045cc0cf0e0636_64ef57c024092be73fa636f3_%25E7%25BC%2596%25E7%25BB%2584%25205%25403x.png",
          width: "579",
          alt: ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
      _c("div", { staticClass: "avatarkit-circle-feature" }, [
        _c("img", {
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64ef58f7a1f1670c8cf21822_icons8-bullseye-96.png",
            loading: "lazy",
            width: "60",
            alt: ""
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
      _c("div", { staticClass: "avatarkit-circle-feature" }, [
        _c("img", {
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64ef58f7a1f1670c8cf2181c_icons8-flag-in-hole-96.png",
            loading: "lazy",
            width: "60",
            alt: ""
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatarkit-mg-20px-bottom" }, [
      _c("div", { staticClass: "avatarkit-circle-feature" }, [
        _c("img", {
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64effebb5d77ed5cd88c6921_world.svg",
            loading: "lazy",
            width: "60",
            alt: ""
          }
        })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fbfe-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64efff9cb2907b8d87839ba1_%E7%BC%96%E7%BB%84%207.svg",
            alt: "",
            height: "200"
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc00-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64efffed3fd0e9128d226eb2_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD.svg",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc02-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f0002ee7effe6dcb578545_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%202.svg",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc04-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f0018bb2907b8d8785e874_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%203.svg",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc06-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f001c0f50c9e714411fadd_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%203.svg",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc08-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f001e8c957e9ad306f0a7a_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%205.svg",
            alt: ""
          }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "avatarkit-logo-card",
        attrs: { id: "w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc0a-0d235eea" }
      },
      [
        _c("img", {
          staticClass: "avatarkit-logo",
          attrs: {
            src:
              "https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f00224ae5329c81e5b7c6b_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%206.svg",
            alt: ""
          }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }