export default {
  message: {
    msg1: '诞生于2015年，是一个为东南亚区域量身定制，安全且快捷的线上购物平台，该公司规划了一种非常先进、互惠互利的商业模式。',
    msg2: '东南亚首选在线购物平台',
    msg3: '进入商城',
    msg4: '先进的商业策略',
    msg5: '采纳互惠互利的模式，共创繁荣。',
    msg6: '东南亚的零售巨头',
    msg7: '市场领先者，赢得消费者的信赖。',
    msg8: '全球与在地品牌汇聚',
    msg9: '为您提供国际与本土的一站式购物体验。',
    msg10: '关于',
    msg11: '总部设在新加坡。',
    msg12: '现已是东南亚市场领先的网上购物商城，提供众多国际和本土品牌的一站式购物服务。',
    msg13: '我们的市场',
    msg14: '目前主营市场是新加坡、马来西亚、印度尼西亚、泰国、越南、中国台湾',
    msg15: '2023开始重点发展日本市场。',
    msg16: '更多地区，敬请期待',
    msg17: '的使命',
    msg18: '是要提供一個全球交易平台，让任何人都能在其中交易任何物品。',
    zhHans: '中文简体',
    en: 'English',
    fr: 'Français',
    ko: '한국어',
    ja: '日本語',
    zhHant: '中文繁体',
    de: 'Deutsch',
    es: 'Español',
    ru: 'Русский язык',
    th: 'ภาษาไทย',
    pt: 'Português',
    vnm: 'Tiếng Việt',
    msg19: '隐私政策',
    msg20: '简介',
    msg21: '欢迎访问NA-Walmart商店网站（单独和统称为“NA-Walmart”、“我们”、“我们的”或“我们的”）。 NA-Walmart 认真履行适用隐私法律法规（“隐私法”）规定的责任，并承诺尊重我们，NA-Walmart 网站和移动应用程序（“平台”）所有用户的隐私权和顾虑（我们 将平台和我们在平台上描述的我们提供的服务统称为“服务”）。用户是指为使用服务而向我们注册账户的用户，包括买家和卖家（单独和统称为“用户”、“您”或“您的”）。 我们认识到您委托给我们的个人数据的重要性，并相信我们有责任妥善管理、保护和处理您的个人数据。本隐私政策（“隐私政策”或“政策”）旨在帮助您了解我们如何收集、使用、披露和处理您提供给我们以及我们拥有的关于您的个人数据，无论是现在还是将来， 以及在向我们提供您的任何个人数据之前帮助您做出明智的决定。',
    msg22: '个人数据是指关于可以从该数据或从该数据和组织已经或可能可以访问的其他信息中识别的个人的数据，无论是否真实。 个人数据的常见示例包括姓名、身份证号码和联系信息。 ',
    msg23: '通过使用服务、在我们这里注册帐户、访问我们的平台或访问服务，您承认并同意您接受本隐私政策中概述的做法、要求和政策。 您特此同意我们按照此处所述收集、使用、披露和处理您的个人数据。如果您不同意按照本隐私政策中的描述处理您的个人数据，请不要使用我们的服务或访问我们的平台。 如果我们更改我们的隐私政策，我们将通过在我们的平台上发布这些更改或修订后的隐私政策来通知您。我们保留随时修改本隐私政策的权利。 在适用法律允许的最大范围内，您可以使用平台上的服务，包括下订单。 ',
    msg24: '本政策与适用于我们收集、存储、使用、披露和处理您的个人数据的其他通知、合同条款和同意条款一起适用，除非我们另有明确说明，否则无意推翻这些通知或条款.',
    msg25: '除另有明确规定外，本政策适用于使用本服务的买卖双方。',
    msg26: 'NA-Walmart 何时收集个人数据？',
    msg27: '我们将/可能会收集关于您的个人数据：',
    msg28: '当您访问和访问我们的网站时；',
    msg29: '当您提交任何工作申请或表格时，包括但不限于申请表格或与我们的任何职位发布相关的其他表格；',
    msg30: '当您通过电话（可能被录音）、信件、传真、面对面会议、采访、社交媒体平台、电子邮件或通过网站与我们互动时。 它包括但不限于当您访问和访问我们的网站时我们可能部署的 cookie； ',
    msg31: '我们可能会从您、我们的关联方、第三方和其他来源收集关于您的个人数据，包括但不限于业务合作伙伴（如物流或支付服务提供商）、信用或评级机构、营销服务提供商或合作伙伴、推荐人或忠诚度计划、我们服务的其他用户或公开可用或政府数据源。 ',
    msg32: '在某些情况下，您可能会向我们提供其他人（例如您的家人或朋友或您的联系人列表中的人）的个人数据。 如果您向我们提供他们的个人数据，您声明并保证您已获得他们的同意，根据本政策处理他们的个人数据。 ',
    msg33: 'NA-Walmart 收集哪些个人数据？',
    msg34: 'NA-Walmart 可能收集的个人数据包括但不限于：',
    msg35: '姓名;',
    msg36: '电子邮件地址;',
    msg37: '出生日期;',
    msg38: '家庭地址;',
    msg39: '电话号码;',
    msg40: '您同意不向我们提交任何不准确或具有误导性的信息，并且您同意将此类信息的任何不准确或变更通知我们。 我们保留要求提供进一步文件以验证您提供的任何信息的权利。 ',
    msg41: '退货政策',
    msg42: '我改变主意了',
    msg43: '您可以在取货或送货后 15 天内退货。',
    msg44: '您的物品必须是：',
    msg45: '未使用且具有所有原始组件；',
    msg46: '在原始包装中（如果适用，带有标签）；',
    msg47: '产品支持',
    msg48: '在大多数情况下，如果您有任何物品问题，您可以在家中舒适地查看我们的产品支持网站，看看我们是否可以解决问题。 在那里，您可以访问有关产品和我们公司的常见问题解答、运输和取件、退货和退款。 ',
    msg49: '我的货物有问题',
    msg50: '如果您仍然需要退货（前 15 天内），我们将为您提供换货、更换或退款。 ',
    msg51: '在此期限之后，只要产品仍在保修期内，我们将使用我们的专业维修代理安排维修，或者，如果无法维修，则更换或更换您的商品。 最快的退货方式是通过我们位于 112 个国家/地区的全球仓储中心。我们可以接受除大件物品外的大多数物品，即使它们最初是送货上门的。 找到离您最近的商店。 如果您要退回大件商品，请向下滚动以开始实时聊天或前往我们的帮助热线链接。',
    msg52: '送货及取货',
    msg53: '你在哪里送货？',
    msg54: '要了解我们是否运送到您所在的地区，请在您要购买的商品上输入您的邮政编码。 产品直接从我们的供应商处发货，我们的全球仓库遍布 112 个国家/地区。',
    msg55: '去哪里取？',
    msg56: '我们在 NA-Walmart 商店和全球仓库的收集点全天候 24/7 运营。 ',
    msg57: '对于可用于快速通道交付的物品，请根据您所在的位置从多个时间段中进行选择。 订单将送货上门并存放在安全可靠的地方。 ',
    msg58: '常见问题',
    msg59: '如果我同时订购大件和小件商品怎么办？',
    msg60: '将它们送到您手中的最快方式是单独运送。 不用担心; 您只需支付一次运费。 请查看您的订单确认电子邮件以获取更多信息。 ',
    msg61: '如果我不在家怎么办？',
    msg62: '小物品将留在安全可靠的地方，在看不见的地方，或应您的要求交给您的邻居。 大件或贵重物品需要照片或签名且不能留下，因此必须有人在家领取。如果您无法赶到，请通过帮助热线联系我们，以便我们重新安排送货时间并选择最适合您的日期和时间段！ ',
    msg63: '客户服务',
    msg64: '在线客服',
    msg65: 'App下载(买家端) ',
    msg66: 'App下载(卖家端) ',
    msg67: '退货和换货',
    msg68: '隐私政策',
    msg69: '退货政策',
    msg70: '送货及取货',
    msg71: '用户中心',
    msg72: '用户注册',
    msg73: '商家登录',
    msg74: '用户协议',
    msg75: '关于我们',
    msg76: '招贤纳士',
    msg77: '现代奴隶声明'
  }
};