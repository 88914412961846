export default {
  message: {
    "msg1": "Nascida em 2015, é uma plataforma de compras online segura e rápida adaptada para a região do Sudeste Asiático. A empresa planejou um modelo de negócios altamente avançado e mutuamente benéfico.",
    "msg2": "Plataforma de compras online preferida do Sudeste Asiático",
    "msg3": "Entra no centro comercial.",
    "msg4": "Estratégia empresarial avançada",
    "msg5": "Adotar um modelo mutuamente benéfico e criar prosperidade juntos.",
    "msg6": "Gigantes do varejo no Sudeste Asiático",
    "msg7": "Líder de mercado, ganhando a confiança dos consumidores.",
    "msg8": "Convergência de marcas globais e locais",
    "msg9": "Nós fornecemos-lhe uma experiência de compras one-stop internacional e localmente.",
    "msg10": "cerca de",
    "msg11": "A sede está localizada em Singapura.",
    "msg12": "É agora um shopping center on-line líder no mercado do Sudeste Asiático, fornecendo serviços de compras one-stop para inúmeras marcas internacionais e locais.",
    "msg13": "O nosso mercado",
    "msg14": "Atualmente, os principais mercados são Singapura, Malásia, Indonésia, Tailândia, Vietnã e Taiwan, China",
    "msg15": "A partir de 2023, vamos nos concentrar no desenvolvimento do mercado japonês.",
    "msg16": "Mais regiões, fiquem atentos",
    "msg17": "A nossa missão",
    "msg18": "É fornecer uma plataforma de negociação global onde qualquer pessoa pode negociar qualquer item.",
    "msg19": "Política de Privacidade",
    "msg20": "breve introdução",
    "msg21": "Bem-vindo ao site da loja NA-Walmart (individual e coletivamente referido como \"NA-Walmart\", \"nos\", \"nosso\" ou \"nosso\"). A NA-Walmart cumpre conscienciosamente as suas responsabilidades ao abrigo das leis e regulamentos de privacidade aplicáveis (a \"Lei de Privacidade\") e promete respeitar os direitos e preocupações de privacidade de todos os utilizadores do site e aplicações móveis da NA-Walmart (a \"Plataforma\") (referimo-nos coletivamente à Plataforma e aos serviços que prestamos na Plataforma como os \"Serviços\"). Os usuários referem-se a usuários que registram contas conosco para fins de uso do serviço, incluindo compradores e vendedores (individualmente e coletivamente referidos como \"usuários\", \"você\" ou \"seu\"). Reconhecemos a importância dos dados pessoais que nos confiou e acreditamos que temos a responsabilidade de gerir, proteger e processar adequadamente os seus dados pessoais. Esta política de privacidade (\"Política de Privacidade\" ou \"Política\") visa ajudá-lo a entender como coletamos, usamos, divulgamos e processamos dados pessoais que você nos fornece e que possuímos sobre você, seja agora ou no futuro, e para ajudá-lo a tomar decisões informadas antes de nos fornecer qualquer um dos seus dados pessoais.",
    "msg22": "Dados pessoais referem-se a dados sobre indivíduos que podem ser identificados a partir desses dados ou outras informações a que a organização tenha ou possa ter acesso, independentemente de serem genuínas ou não. Exemplos comuns de dados pessoais incluem nomes, números de identificação e informações de contato.",
    "msg23": "Ao usar o serviço, registrar uma conta conosco, acessar nossa plataforma ou acessar o serviço, você reconhece e concorda que aceita as práticas, requisitos e políticas descritas nesta política de privacidade. Você concorda que coletaremos, usaremos, divulgaremos e processaremos seus dados pessoais conforme descrito aqui. Se você não concordar em processar seus dados pessoais conforme descrito nesta política de privacidade, por favor não use nossos serviços ou acesse nossa plataforma. Se alterarmos nossa política de privacidade, notificaremos você publicando essas alterações ou políticas de privacidade revisadas em nossa plataforma. Reservamo-nos o direito de modificar esta política de privacidade a qualquer momento. Na extensão máxima permitida pela lei aplicável, você pode usar os serviços na plataforma, incluindo a realização de pedidos.",
    "msg24": "Esta política aplica-se em conjunto com outros avisos, termos contratuais e termos de consentimento aplicáveis à nossa coleta, armazenamento, uso, divulgação e processamento de seus dados pessoais. Salvo indicação expressa em contrário, não pretendemos substituir esses avisos ou termos",
    "msg25": "Salvo especificação em contrário, esta política aplica-se a compradores e vendedores que usam este serviço.",
    "msg26": "Quando é que a NA-Walmart recolhe dados pessoais?",
    "msg27": "Iremos/podemos recolher dados pessoais sobre si:",
    "msg28": "Quando visita e acede ao nosso website;",
    "msg29": "Quando você enviar qualquer inscrição ou formulário de emprego, incluindo, mas não limitado a, o formulário de inscrição ou outros formulários relacionados a qualquer um de nossos anúncios de emprego;",
    "msg30": "Quando interage connosco através de chamadas telefónicas (que podem ser gravadas), cartas, faxes, reuniões presenciais, entrevistas, plataformas de redes sociais, e-mails ou através de websites. Inclui, mas não se limita a, cookies que podemos implementar quando você visita e acessa nosso site;",
    "msg31": "Podemos coletar dados pessoais sobre você de você, nossas afiliadas, terceiros e outras fontes, incluindo, mas não limitado a parceiros de negócios (como provedores de serviços de logística ou pagamento), agências de crédito ou classificação, provedores de serviços de marketing ou parceiros, referências ou programas de fidelidade, outros usuários de nossos serviços ou fontes de dados publicamente disponíveis ou governamentais.",
    "msg32": "Em alguns casos, você pode nos fornecer dados pessoais sobre outras pessoas, como sua família ou amigos ou pessoas em sua lista de contatos. Se nos fornecer os seus dados pessoais, declara e garante que obteve o seu consentimento para processar os seus dados pessoais de acordo com esta política.",
    "msg33": "Que dados pessoais a NA-Walmart recolhe?",
    "msg34": "Os dados pessoais que a NA-Walmart pode recolher incluem, mas não se limitam a:",
    "msg35": "Nome;",
    "msg36": "Endereço electrónico;",
    "msg37": "Data de nascimento;",
    "msg38": "Endereço residencial;",
    "msg39": "Número de telefone",
    "msg40": "Você concorda em não enviar qualquer informação imprecisa ou enganosa para nós, e você concorda em nos notificar de quaisquer imprecisões ou alterações em tais informações. Reservamo-nos o direito de solicitar outros documentos para verificar qualquer informação que você fornecer.",
    "msg41": "política de regresso",
    "msg42": "Mudei de ideias",
    "msg43": "Você pode devolver o item no prazo de 15 dias após a coleta ou entrega.",
    "msg44": "Seu item deve ser:",
    "msg45": "Não utilizado e com todos os componentes originais;",
    "msg46": "Na embalagem original (com etiquetas, se aplicável);",
    "msg47": "Suporte ao Produto",
    "msg48": "Na maioria dos casos, se você tiver algum problema de item, você pode verificar confortavelmente o nosso site de suporte ao produto em casa para ver se podemos resolver o problema. Lá, você pode acessar perguntas frequentes sobre produtos e nossa empresa, transporte e coleta, devoluções e reembolsos.",
    "msg49": "Há um problema com os meus bens.",
    "msg50": "Se você ainda precisar de uma devolução (dentro dos primeiros 15 dias), forneceremos uma troca, substituição ou reembolso.",
    "msg51": "Após este período, enquanto o produto ainda estiver sob garantia, usaremos nosso agente de reparo profissional para organizar reparos, ou se reparos não forem possíveis, substitua ou substitua seu produto. A maneira mais rápida de devolver mercadorias é através de nossos centros de armazenamento globais localizados em 112 países/regiões. Podemos aceitar a maioria dos itens, exceto para itens grandes, mesmo se eles foram originalmente entregues à nossa porta. Encontra a loja mais próxima de ti. Se você quiser devolver um item grande, role para baixo para iniciar um chat em tempo real ou vá para a nossa linha direta de ajuda.",
    "msg52": "Entrega e recolha",
    "msg53": "Onde entrega a mercadoria?",
    "msg54": "Para saber se enviamos para sua região, digite seu código postal no item que você está comprando. Os produtos são enviados diretamente de nossos fornecedores, e nossos armazéns globais estão localizados em 112 países / regiões.",
    "msg55": "Onde o posso arranjar?",
    "msg56": "Operamos 24 horas por dia, 7 dias por semana em nossos pontos de coleta em lojas NA-Walmart e armazéns globais.",
    "msg57": "Para itens que podem ser usados para entrega rápida, escolha entre vários períodos de tempo com base em sua localização. O pedido será entregue à sua porta e armazenado em um local seguro e confiável.",
    "msg58": "Perguntas Frequentes",
    "msg59": "E se eu encomendar itens grandes e pequenos ao mesmo tempo?",
    "msg60": "A maneira mais rápida de entregá-los a você é enviá-los separadamente. Não te preocupes. Você só precisa pagar a taxa de envio uma vez. Por favor, verifique seu e-mail de confirmação do pedido para mais informações.",
    "msg61": "E se eu não estiver em casa?",
    "msg62": "Pequenos itens serão deixados em um lugar seguro e confiável, fora da vista, ou entregues aos seus vizinhos a seu pedido. Itens grandes ou valiosos exigem fotos ou assinaturas e não podem ser deixados para trás, então alguém deve coletá-los em casa. Se você não puder chegar, entre em contato conosco através da linha direta de ajuda para que possamos reagendar o tempo de entrega e escolher a data e período de tempo mais adequados para você!",
    "msg63": "atendimento ao cliente",
    "msg64": "Serviço Online",
    "msg65": "Transferência da aplicação (lado do comprador)",
    "msg66": "Transferência da aplicação (lado do vendedor)",
    "msg67": "Devoluções e Trocas",
    "msg68": "Política de Privacidade",
    "msg69": "política de regresso",
    "msg70": "Entrega e recolha",
    "msg71": "Centro do Utilizador",
    "msg72": "Registo do utilizador",
    "msg73": "Registo do comerciante",
    "msg74": "Acordo do Utilizador",
    "msg75": "Sobre nós",
    "msg76": "Recrutamento de Talentos",
    "msg77": "Declaração dos Escravos Modernos"
  }
};