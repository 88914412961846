import "core-js/modules/es6.promise";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es6.string.starts-with";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import Vue from 'vue';
import axios from 'axios';
import store from '../store';

var showStatus = function showStatus(status) {
  var message = '';

  switch (status) {
    case 400:
      message = '请求错误(400)';
      break;

    case 401:
      message = '未受权，请从新登陆(401)';
      break;

    case 403:
      message = '拒绝访问(403)';
      break;

    case 404:
      message = '请求出错(404)';
      break;

    case 408:
      message = '请求超时(408)';
      break;

    case 500:
      message = '服务器错误(500)';
      break;

    case 501:
      message = '服务未实现(501)';
      break;

    case 502:
      message = '网络错误(502)';
      break;

    case 503:
      message = '服务不可用(503)';
      break;

    case 504:
      message = '网络超时(504)';
      break;

    case 505:
      message = 'HTTP版本不受支持(505)';
      break;

    default:
      message = "\u94FE\u63A5\u51FA\u9519(".concat(status, ")!");
  }

  _Toast.fail("".concat(message));

  return "".concat(message, "\uFF0C\u8BF7\u68C0\u67E5\u7F51\u7EDC\u6216\u8054\u7CFB\u7BA1\u7406\u5458\uFF01");
};

var service = axios.create({
  // 联调
  baseURL: process.env.NODE_ENV === 'production' ? "https://api.maldeki93r.top/api" : 'https://api.maldeki93r.top/api',
  // baseURL: '/api',
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  },
  // 是否跨站点访问控制请求
  //withCredentials: true,
  timeout: 30000,
  transformRequest: [function (data) {
    data = JSON.stringify(data);
    return data;
  }],
  validateStatus: function validateStatus() {
    // 使用async-await，处理reject状况较为繁琐，因此所有返回resolve，在业务代码中处理异常
    return true;
  },
  transformResponse: [function (data) {
    if (typeof data === 'string' && data.startsWith('{')) {
      data = JSON.parse(data);
    }

    return data;
  }]
}); // 请求拦截器

service.interceptors.request.use(function (config) {
  // Toast.loading({
  //   message: '查询中...',
  //   forbidClick: true,
  // });
  var headers = Object.assign({}, config.headers, {
    'x-access-token': window.localStorage.getItem('USER_TOKEN')
  });

  if (window.localStorage.getItem('USER_TOKEN')) {
    config.headers = headers;
  }

  return config;
}, function (error) {
  // 错误抛到业务代码
  error.data = {};
  error.data.msg = '服务器异常，请联系管理员！';
  return Promise.resolve(error);
}); // 响应拦截器

service.interceptors.response.use(function (response) {
  var status = response.status;
  var successRes = {};
  var msg = '';

  if (status < 200 || status >= 300) {
    // 处理http错误，抛到业务代码
    msg = showStatus(status);

    if (typeof response.data === 'string') {
      response.data = {
        msg: msg
      };
    } else {
      response.data.msg = msg;
    }
  } else {
    successRes = response.data;
  }

  setTimeout(function () {
    _Toast.clear();
  }, 500);
  return successRes;
}, function (error) {
  // 错误抛到业务代码
  error.data = {};
  error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！';
  return Promise.resolve(error);
});
export function serviceGet(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    service({
      url: url,
      method: 'get',
      params: params
    }).then(function (res) {
      resolve(res);
    })["catch"](function (err) {
      reject(err);
    });
  });
}
export function servicePost(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    service({
      url: url,
      method: 'post',
      data: params
    }).then(function (res) {
      resolve(res);
    })["catch"](function (err) {
      reject(err);
    });
  });
}
export function serviceBlob(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    service({
      url: url,
      method: 'post',
      responseType: 'blob',
      data: params
    }).then(function (res) {
      resolve(res);
    })["catch"](function (err) {
      reject(err);
    });
  });
}