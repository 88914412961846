export default {
  message: {
    "msg1": "誕生於2015年，是一個為東南亞區域量身定制，安全且快捷的線上購物平臺，該公司規劃了一種非常先進、互惠互利的商業模式。",
    "msg2": "東南亞首選線上購物平臺",
    "msg3": "進入商城",
    "msg4": "先進的商業策略",
    "msg5": "採納互惠互利的模式，共創繁榮。",
    "msg6": "東南亞的零售巨頭",
    "msg7": "市場領先者，贏得消費者的信賴。",
    "msg8": "全球與在地品牌彙聚",
    "msg9": "為您提供國際與本土的一站式購物體驗。",
    "msg10": "關於",
    "msg11": "總部設在新加坡。",
    "msg12": "現已是東南亞市場領先的網上購物商城，提供眾多國際和本土品牌的一站式購物服務。",
    "msg13": "我們的市場",
    "msg14": "現時主營市場是新加坡、馬來西亞、印尼、泰國、越南、中國臺灣",
    "msg15": "2023開始重點發展日本市場。",
    "msg16": "更多地區，敬請期待",
    "msg17": "的使命",
    "msg18": "是要提供一個全球交易平臺，讓任何人都能在其中交易任何物品。",
    "msg19": "隱私政策",
    "msg20": "簡介",
    "msg21": "歡迎訪問NA-Walmart商店網站（單獨和統稱為“NA-Walmart”、“我們”、“我們的”或“我們的”）。 NA-Walmart認真履行適用隱私法律法規（“隱私法”）規定的責任，並承諾尊重我們，NA-Walmart網站和移動應用程序（“平臺”）所有用戶的隱私權和顧慮（我們將平臺和我們在平臺上描述的我們提供的服務統稱為“服務”）。 用戶是指為使用服務而向我們注册帳戶的用戶，包括買家和賣家（單獨和統稱為“用戶”、“您”或“您的”）。 我們認識到您委託給我們的個人數據的重要性，並相信我們有責任妥善管理、保護和處理您的個人數據。 本隱私政策（“隱私政策”或“政策”）旨在幫助您瞭解我們如何收集、使用、披露和處理您提供給我們以及我們擁有的關於您的個人數據，無論是現在還是將來，以及在向我們提供您的任何個人數據之前幫助您做出明智的决定。",
    "msg22": "個人數據是指關於可以從該數據或從該數據和組織已經或可能可以訪問的其他資訊中識別的個人的數據，無論是否真實。 個人數據的常見示例包括姓名、身份證號碼和聯系資訊。",
    "msg23": "通過使用服務、在我們這裡注册帳戶、訪問我們的平臺或訪問服務，您承認並同意您接受本隱私政策中概述的做法、要求和政策。 您特此同意我們按照此處所述收集、使用、披露和處理您的個人數據。 如果您不同意按照本隱私政策中的描述處理您的個人數據，請不要使用我們的服務或訪問我們的平臺。 如果我們更改我們的隱私政策，我們將通過在我們的平臺上發佈這些更改或修訂後的隱私政策來通知您。 我們保留隨時修改本隱私政策的權利。 在適用法律允許的最大範圍內，您可以使用平臺上的服務，包括下訂單。",
    "msg24": "本政策與適用於我們收集、存儲、使用、披露和處理您的個人數據的其他通知、契约條款和同意條款一起適用，除非我們另有明確說明，否則無意推翻這些通知或條款.",
    "msg25": "除另有明確規定外，本政策適用於使用本服務的買賣雙方。",
    "msg26": "NA-Walmart何時收集個人數據？",
    "msg27": "我們將/可能會收集關於您的個人數據：",
    "msg28": "當您訪問和訪問我們的網站時；",
    "msg29": "當您提交任何工作申請或表格時，包括但不限於申請表格或與我們的任何職位發佈相關的其他表格；",
    "msg30": "當您通過電話（可能被錄音）、信件、傳真、面對面會議、採訪、社交媒體平臺、電子郵件或通過網站與我們互動時。 它包括但不限於當您訪問和訪問我們的網站時我們可能部署的cookie；",
    "msg31": "我們可能會從您、我們的關聯方、協力廠商和其他來源收集關於您的個人數據，包括但不限於業務合作夥伴（如物流或支付服務提供者）、信用或評級機構、行銷服務提供者或合作夥伴、推薦人或忠誠度計畫、我們服務的其他用戶或公開可用或政府資料來源。",
    "msg32": "在某些情况下，您可能會向我們提供其他人（例如您的家人或朋友或您的連絡人清單中的人）的個人數據。 如果您向我們提供他們的個人數據，您聲明並保證您已獲得他們的同意，根據本政策處理他們的個人數據。",
    "msg33": "NA-Walmart收集哪些個人數據？",
    "msg34": "NA-Walmart可能收集的個人數據包括但不限於：",
    "msg35": "姓名；",
    "msg36": "電子郵寄地址；",
    "msg37": "出生日期；",
    "msg38": "家庭地址；",
    "msg39": "電話號碼；",
    "msg40": "您同意不向我們提交任何不準確或具有誤導性的資訊，並且您同意將此類資訊的任何不準確或變更通知我們。 我們保留要求提供進一步檔案以驗證您提供的任何資訊的權利。",
    "msg41": "退貨政策",
    "msg42": "我改變主意了",
    "msg43": "您可以在取貨或送貨後15天內退貨。",
    "msg44": "您的物品必須是：",
    "msg45": "未使用且具有所有原始組件；",
    "msg46": "在原始包裝中（如果適用，帶有標籤）；",
    "msg47": "產品支持",
    "msg48": "在大多數情况下，如果您有任何物品問題，您可以在家中舒適地查看我們的產品支持網站，看看我們是否可以解决問題。 在那裡，您可以訪問有關產品和我們公司的常見問題解答、運輸和取件、退貨和退款。",
    "msg49": "我的貨物有問題",
    "msg50": "如果您仍然需要退貨（前15天內），我們將為您提供換貨、更換或退款。",
    "msg51": "在此期限之後，只要產品仍在保修期內，我們將使用我們的專業維修代理安排維修，或者，如果無法維修，則更換或更換您的商品。 最快的退貨管道是通過我們位於112個國家/地區的全球倉儲中心。 我們可以接受除大件物品外的大多數物品，即使它們最初是送貨上門的。 找到離您最近的商店。 如果您要退回大件商品，請向下滾動以開始實时聊天或前往我們的幫助熱線連結。",
    "msg52": "送貨及取貨",
    "msg53": "你在哪裡送貨？",
    "msg54": "要瞭解我們是否運送到您所在的地區，請在您要購買的商品上輸入您的郵遞區號。 產品直接從我們的供應商處發貨，我們的全球倉庫遍佈112個國家/地區。",
    "msg55": "去哪裡取？",
    "msg56": "我們在NA-Walmart商店和全球倉庫的收集點全天候24/7運營。",
    "msg57": "對於可用於快速通道交付的物品，請根據您所在的位置從多個時間段中進行選擇。 訂單將送貨上門並存放在安全可靠的地方。",
    "msg58": "常見問題",
    "msg59": "如果我同時訂購大件和小件商品怎麼辦？",
    "msg60": "將它們送到您手中的最快管道是單獨運送。 不用擔心； 您只需支付一次運費。 請查看您的訂單確認電子郵件以獲取更多資訊。",
    "msg61": "如果我不在家怎麼辦？",
    "msg62": "小物品將留在安全可靠的地方，在看不見的地方，或應您的要求交給您的鄰居。 大件或貴重物品需要照片或簽名且不能留下，囙此必須有人在家領取。 如果您無法趕到，請通過幫助熱線聯繫我們，以便我們重新安排送貨時間並選擇最適合您的日期和時間段！",
    "msg63": "客戶服務",
    "msg64": "線上客服",
    "msg65": "App下載（買家端）",
    "msg66": "App下載（賣家端）",
    "msg67": "退貨和換貨",
    "msg68": "隱私政策",
    "msg69": "退貨政策",
    "msg70": "送貨及取貨",
    "msg71": "用戶中心",
    "msg72": "用戶註冊",
    "msg73": "商家登入",
    "msg74": "使用者協定",
    "msg75": "關於我們",
    "msg76": "招賢納士",
    "msg77": "現代奴隸聲明"
  }
};