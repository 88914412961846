var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avatarkit-footer-black" }, [
    _c("div", { staticClass: "avatarkit-container-component w-container" }, [
      _c("div", { staticClass: "footer-box" }, [
        _c("div", { staticClass: "footer-box-item" }, [
          _c("div", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("message.msg63")))
          ]),
          _c(
            "a",
            {
              staticClass: "f-section",
              attrs: { href: _vm.platformData.platform_kf_url }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg64")))]
          ),
          _c(
            "a",
            {
              staticClass: "f-section",
              attrs: { href: _vm.platformData.buyer_app_down_url }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg65")))]
          ),
          _c(
            "a",
            {
              staticClass: "f-section",
              attrs: { href: _vm.platformData.seller_app_down_url }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg66")))]
          )
        ]),
        _c("div", { staticClass: "footer-box-item" }, [
          _c("div", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("message.msg67")))
          ]),
          _c(
            "div",
            {
              staticClass: "f-section",
              on: {
                click: function($event) {
                  return _vm.goYs()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg68")))]
          ),
          _c(
            "div",
            {
              staticClass: "f-section",
              on: {
                click: function($event) {
                  return _vm.goTh()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg69")))]
          ),
          _c(
            "div",
            {
              staticClass: "f-section",
              on: {
                click: function($event) {
                  return _vm.goMj()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg70")))]
          )
        ]),
        _c("div", { staticClass: "footer-box-item" }, [
          _c("div", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("message.msg71")))
          ]),
          _c("a", { staticClass: "f-section", attrs: { href: _vm.jumpUrl } }, [
            _vm._v(_vm._s(_vm.$t("message.msg72")))
          ]),
          _c(
            "a",
            {
              staticClass: "f-section",
              attrs: { href: _vm.platformData.shop_wap_url }
            },
            [_vm._v(_vm._s(_vm.$t("message.msg73")))]
          ),
          _c("div", { staticClass: "f-section" }, [
            _vm._v(_vm._s(_vm.$t("message.msg74")))
          ])
        ]),
        _c("div", { staticClass: "footer-box-item" }, [
          _c("div", { staticClass: "tit" }, [
            _vm._v(_vm._s(_vm.$t("message.msg75")))
          ]),
          _c("a", { staticClass: "f-section" }, [
            _vm._v(_vm._s(_vm.$t("message.msg75")))
          ]),
          _c("a", { staticClass: "f-section" }, [
            _vm._v(_vm._s(_vm.$t("message.msg76")))
          ]),
          _c("div", { staticClass: "f-section" }, [
            _vm._v(_vm._s(_vm.$t("message.msg77")))
          ])
        ])
      ])
    ]),
    _vm._m(0),
    _c("div", { staticClass: "copyright" }, [
      _vm._v("Copyright © 2015-2023 NA-Walmart")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "avatarkit-container-component" }, [
      _c("div", { staticClass: "avatarkit-footer-wrapper-center" }, [
        _c("div", { staticClass: "avatarkit-footer-block-center" }, [
          _c("div", { staticClass: "avatarkit-text-footer-wrapper" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }