<template>
  <div>
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease"
      role="banner" class="avatarkit-nav-component w-nav">
      <div class="avatarkit-container-component">
        <!-- 增加某种语言切换的按钮。注意 ul上加了一个 class="ignore" 代表这块代码不会被翻译到 -->
        <div class="avatar-navbar-global-padding-wrapper">
          <a href="#" class="avatarkit-navbar-brand w-nav-brand"><img src="../assets/images/logo.png" loading="lazy"
              width="63" alt="" /></a>
          <div class="col-links">
            <div @click="showLang = !showLang" class="dropdown lang">
              <a href="javascript:void(0)" id="yuyan" class="dropdown-toggle ignore" data-toggle="dropdown" role="button"
                aria-haspopup="true" aria-expanded="false">
                <img class="country-img" :src="langImg" alt="">
                <span id="yuyanText">{{ langName }}</span><i class="caret"></i></a>
              <ul v-show="showLang" id="dropdown-menu" class="dropdown-menu ignore">
                <li class="country-box" @click="changeLang(item)" v-for="(item, index) in langList" :key="index">
                  <img class="country-img" :src="item.country_img" alt="">
                  <a>{{ item.language }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease"
      role="banner" class="avatarkit-nav-component-2 w-nav">
      <div class="avatarkit-container-component-2"></div>
    </div>
    <div class="avatarkit-padding-global-components-bck-green">
      <div class="avatarkit-container-component">
        <div class="avatarkit-grid-two-row">
          <div class="avatarkit-width-small-card">
            <div class="avatarkit-mg-32px-bottom">
              <h1 class="avatarkit-large-text-white">
                <span class="ignore">NA-Walmart</span><br />{{
                  $t("message.msg2")
                }}
              </h1>
            </div>
            <div class="avatarkit-mg-20px-bottom">
              <p class="avatarkit-left-white-text-copy">
                <span class="ignore">NA-Walmart</span>{{ $t("message.msg1") }}
              </p>
            </div>
            <div class="avatarkit-mg-40px-top">
              <a :href="jumpUrl" class="avatarkit-button-primary-white w-button">{{ $t("message.msg3") }}</a>
            </div>
          </div>
          <div class="avatarkit-content-width-left">
            <img
              src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f08ce6c7045cc0cf0e0636_64ef57c024092be73fa636f3_%25E7%25BC%2596%25E7%25BB%2584%25205%25403x.png"
              width="579" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="avatarkit-padding-global-components _2">
      <div class="avatarkit-container-component">
        <div class="w-layout-grid avatarkit-services-grid">
          <div class="avatarkit-feature-card-transparent">
            <div class="avatarkit-mg-20px-bottom">
              <div class="avatarkit-circle-feature">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64ef58f7a1f1670c8cf21822_icons8-bullseye-96.png"
                  loading="lazy" width="60" alt="" />
              </div>
            </div>
            <div class="avatarkit-mg-20px-bottom">
              <h5 class="avatarkit-service-title">{{ $t("message.msg4") }}</h5>
            </div>
            <p class="avatarkit-center-text">{{ $t("message.msg5") }}</p>
          </div>
          <div class="avatarkit-feature-card-transparent">
            <div class="avatarkit-mg-20px-bottom">
              <div class="avatarkit-circle-feature">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64ef58f7a1f1670c8cf2181c_icons8-flag-in-hole-96.png"
                  loading="lazy" width="60" alt="" />
              </div>
            </div>
            <div class="avatarkit-mg-20px-bottom">
              <h5 class="avatarkit-service-title">{{ $t("message.msg6") }}</h5>
            </div>
            <p class="avatarkit-center-text">{{ $t("message.msg7") }}</p>
          </div>
          <div class="avatarkit-feature-card-transparent">
            <div class="avatarkit-mg-20px-bottom">
              <div class="avatarkit-circle-feature">
                <img src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64effebb5d77ed5cd88c6921_world.svg"
                  loading="lazy" width="60" alt="" />
              </div>
            </div>
            <div class="avatarkit-mg-20px-bottom">
              <h5 class="avatarkit-service-title">{{ $t("message.msg8") }}</h5>
            </div>
            <p class="avatarkit-center-text">
              {{ $t("message.msg9") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <section class="hero-stack">
      <div class="container">
        <div class="hero-wrapper-two">
          <h1 class="heading">
            {{ $t("message.msg10") }}<span class="ignore">NA-Walmart</span>
          </h1>
          <p class="margin-bottom-24px">
            <span class="ignore">NA-Walmart</span>{{ $t("message.msg11") }}
            <span class="ignore">NA-Walmart</span>{{ $t("message.msg12") }}
          </p>
          <img
            src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f08d965a2be2cc299972a1_64f012e9886ecb205028b6c5_online-shopping%20(1)%402x%20(1).png"
            loading="lazy" alt="" class="hero-image shadow-two" />
        </div>
      </div>
    </section>
    <div class="avatarkit-padding-global-components _5">
      <div class="avatarkit-container-component">
        <div class="avatarkit-grid-two-row">
          <div class="avatarkit-content-width-left">
            <div class="avatarkit-mg-32px-bottom">
              <h3 class="avatarkit-large-header">{{ $t("message.msg13") }}</h3>
            </div>
            <div class="avatarkit-mg-20px-bottom">
              <p class="avatarkit-left-text-copy">
                {{ $t("message.msg14") }}<br />{{ $t("message.msg15") }}
              </p>
            </div>
            <div class="avatarkit-mg-40px-top">
              <a :href="jumpUrl" class="avatarkit-button-primary-2 w-button">{{ $t("message.msg3") }}</a>
            </div>
          </div>
          <div class="avatarkit-width-small-card">
            <div class="w-layout-grid avatarkit-logo-grid-right">
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fbfe-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64efff9cb2907b8d87839ba1_%E7%BC%96%E7%BB%84%207.svg"
                  alt="" height="200" class="avatarkit-logo" />
              </div>
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc00-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64efffed3fd0e9128d226eb2_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD.svg"
                  alt="" class="avatarkit-logo" />
              </div>
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc02-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f0002ee7effe6dcb578545_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%202.svg"
                  alt="" class="avatarkit-logo" />
              </div>
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc04-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f0018bb2907b8d8785e874_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%203.svg"
                  alt="" class="avatarkit-logo" />
              </div>
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc06-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f001c0f50c9e714411fadd_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%203.svg"
                  alt="" class="avatarkit-logo" />
              </div>
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc08-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f001e8c957e9ad306f0a7a_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%205.svg"
                  alt="" class="avatarkit-logo" />
              </div>
              <div id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc0a-0d235eea" class="avatarkit-logo-card">
                <img
                  src="https://uploads-ssl.webflow.com/64ef56bc1c7dc47fa1a301de/64f00224ae5329c81e5b7c6b_%E7%BC%96%E7%BB%84%207%E5%A4%87%E4%BB%BD%206.svg"
                  alt="" class="avatarkit-logo" />
              </div>
              <p id="w-node-ab7e0a7b-74b5-d2fa-bfff-d22f6c23fc0c-0d235eea" class="paragraph">
                {{ $t("message.msg16") }}...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="avatarkit-padding-global-components _1">
      <div class="avatarkit-container-component">
        <div class="avatarkit-container-component">
          <div class="avatarkit-cta-info">
            <div class="avatarkit-mg-20px-bottom">
              <h3 class="avatarkit-large-header">
                <span class="ignore">NA-Walmart</span>{{ $t("message.msg17") }}
              </h3>
              <p class="avatarkit-left-text-copy">
                {{ $t("message.msg18") }}
              </p>
            </div>
            <div class="avatarkit-mg-40px-top">
              <a :href="jumpUrl" class="avatarkit-button-primary-3 w-button">{{ $t("message.msg3") }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot :jumpUrl="jumpUrl" :platformData="platformData" />
  </div>
</template>
<script>
import { servicePost, serviceGet } from '@/utils/http'
import api from '@/api/index'
import foot from '@/components/foot'

export default {
  data() {
    return {
      jumpUrl: "",
      langName: "",
      langImg: "",
      showLang: false,
      langList: [
        // {
        //   code: "zh-Hans",
        //   language: "中文简体",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/07d4a84dc5db87cf14587c149ae458cf.png",
        //   country_id: 1,
        //   country_name: "China",
        // },
        {
          code: "en",
          language: "English",
          // country_img:
          //   "https://cbec-file.wo-shop.net/uploads/country/20230903/64a47eccacd186fbfc569cf64fd78cdb.png",
          country_img: require('../assets/images/64a47eccacd186fbfc569cf64fd78cdb.png'),
          country_id: 230,
          country_name: "United States",
        },
        // {
        //   code: "fr",
        //   language: "Français",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/7f4d33b038d571e9ae7524f6b2553e8c.png",
        //   country_id: 75,
        //   country_name: "France",
        // },
        // {
        //   code: "ko",
        //   language: "한국어",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/5495d26366bb37983a92d9d5844e3d58.png",
        //   country_id: 120,
        //   country_name: "South Korea",
        // },
        {
          code: "ja",
          language: "日本語",
          // country_img:
          //   "https://cbec-file.wo-shop.net/uploads/country/20230903/a88f06b701f922cc5e007e42e11bea06.png",
          country_img: require('../assets/images/a88f06b701f922cc5e007e42e11bea06.png'),
          country_id: 113,
          country_name: "Japan",
        },
        {
          code: "zh-Hant",
          language: "中文繁体",
          // country_img:
          //   "https://cbec-file.wo-shop.net/uploads/country/20230903/26a0fefb2a255f7827484ca5e7c025f3.png",
          country_img: require('../assets/images/26a0fefb2a255f7827484ca5e7c025f3.png'),
          country_id: 247,
          country_name: "HongKong",
        },
        // {
        //   code: "de",
        //   language: "Deutsch",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/faedf63d0cf8d4deb473f87d37e95608.png",
        //   country_id: 59,
        //   country_name: "Germany",
        // },
        // {
        //   code: "es",
        //   language: "Español",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/17671b37b1a765bd51aeb2ada153d568.png",
        //   country_id: 69,
        //   country_name: "Spain",
        // },
        // {
        //   code: "ru",
        //   language: "Русский язык",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/b93053f2e871e7a8928e46eab109a678.png",
        //   country_id: 186,
        //   country_name: "Russia",
        // },
        // {
        //   code: "th",
        //   language: "ภาษาไทย",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/fb41f04e5b0af791ce24ed27e9c453d3.png",
        //   country_id: 215,
        //   country_name: "Thailand",
        // },
        // {
        //   code: "pt",
        //   language: "Português",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/739be0ae8323af619dccf564ac8f664c.png",
        //   country_id: 179,
        //   country_name: "Portugal",
        // },
        // {
        //   code: "vnm",
        //   language: "Tiếng Việt/",
        //   country_img:
        //     "https://cbec-file.wo-shop.net/uploads/country/20230903/530dd5c216c8842aede2fdcbd138ccfa.png",
        //   country_id: 237,
        //   country_name: "Vietnam",
        // },
        {
          code: "idn",
          language: "Bahasa Indonesia",
          country_img:require('../assets/images/W020100909473199948565.jpg'),
          country_id: 237,
          country_name: "Vietnam",
        },
      ],
      scode: '',
      webUrl: '',
      h5Url: '',
      platformData: ''
    };
  },
  components: {
    foot
  },
  created() {
    const { scode } = this.$route.query
    this.scode = scode;
    console.log(this.scode)
    this.getPlatformUrl();
  },
  mounted() {
    this.langName = 'Japan';
    this.langImg = require('../assets/images/a88f06b701f922cc5e007e42e11bea06.png');
  },
  methods: {
    getPlatformUrl() {
      servicePost(api.getPlatformUrl).then(res => {
        this.webUrl = `${res.data.pc_url}?scode=${this.scode}`;
        this.h5Url = `${res.data.wap_url}?scode=${this.scode}`;
        this.platformData = res.data;
        if (this.isMobile()) {
          this.jumpUrl = this.h5Url;
        } else {
          this.jumpUrl = this.webUrl;
        }
      })
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    changeLang(item) {
      this.$i18n.locale = item.code;
      this.langName = item.language;
      this.langImg = item.country_img;
    },
  },
};
</script>
<style>
.col-links {
  width: 40%;
  min-height: auto;
  float: right;
  margin-bottom: 26px;
  margin-top: 30px;
}

#yuyan {
  display: flex;
  align-items: center;
}

#yuyanText {
  margin-left: 5px;
}

.col-links .lang {
  display: inline-block;
  font-size: 14px;
  color: #919191;
  border: 1px solid #ffffff;
  min-width: 100px;
  box-sizing: border-box;
}

.dropdown,
.dropup {
  position: relative;
}

.col-links .lang>a {
  display: block;
  padding: 8px 20px;
  color: #000;
}

.col-links .dropdown-menu {
  min-width: 120px;
  padding-top: 10px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  float: left;
  padding: 5px 0 5px 10px;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  overflow: overlay;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.col-links .lang ul li {
  margin-bottom: 0;
  color: #919191;
}

.col-links .lang ul li a {
  padding: 5px 15px;
  display: block;
  color: #919191;
}

.dropdown-menu>li>a {
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  white-space: nowrap;
}

.open>.dropdown-menu {
  display: block;
}

.dropdown-menu li:hover {
  background: rgba(158, 154, 154, 0.25);
}

a {
  text-decoration: none;
}

.country-img {
  width: 24px;
}

.country-box {
  display: flex;
  align-items: center;
}
</style>