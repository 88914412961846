export default {
  message: {
    msg1: 'Dilahirkan pada tahun 2015, ini adalah platform belanja online yang aman dan cepat yang dirancang untuk Asia Tenggara. Perusahaan telah merencanakan model bisnis yang sangat maju dan saling menguntungkan. ',
    msg2: 'Platform belanja online pilihan di Asia Tenggara',
    msg3: 'Masuk mal',
    msg4: 'Strategi Bisnis Tingkat Lanjut',
    msg5: 'Mengadopsi model yang saling menguntungkan untuk menciptakan kesejahteraan bersama. ',
    msg6: 'Raksasa ritel Asia Tenggara',
    msg7: 'Pemimpin pasar, memenangkan kepercayaan konsumen. ',
    msg8: 'Merek global dan lokal menyatu',
    msg9: 'Memberikan Anda pengalaman berbelanja terpadu internasional dan lokal. ',
    msg10: 'Tentang',
    msg11: 'Berbasis di Singapura. ',
    msg12: 'Sekarang menjadi pusat perbelanjaan online terkemuka di Asia Tenggara, menyediakan layanan one-stop shopping untuk banyak merek internasional dan lokal. ',
    msg13: 'Pasar kami',
    msg14: 'Pasar utama saat ini adalah Singapura, Malaysia, Indonesia, Thailand, Vietnam, dan Taiwan',
    msg15: 'Mulai fokus mengembangkan pasar Jepang pada tahun 2023. ',
    msg16: 'Area lainnya, harap pantau terus',
    msg17: 'Kewajiban',
    msg18: 'Ini untuk menyediakan platform perdagangan global di mana siapa pun dapat memperdagangkan apa pun. '
  }
};