import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import "@/assets/styles/normalize.css";
import "@/assets/styles/webflow.css";
import "@/assets/styles/chans-trendy-site-d9a0dc.webflow.css";
import "@/assets/js/webflow.js";
import router from "./router";
import store from "./store";
import cnlang from "./lang/zh-Hans.js";
import enlang from "./lang/en.js";
import jalang from "./lang/ja.js";
import frlang from "./lang/fr.js";
import kolang from "./lang/ko.js";
import htlang from "./lang/zh-Hant.js";
import delang from "./lang/de.js";
import eslang from "./lang/es.js";
import rulang from "./lang/ru.js";
import thlang from "./lang/th.js";
import ptlang from "./lang/pt.js";
import vnmlang from "./lang/vnm.js";
import idn from "./lang/idn.js";
Vue.use(VueI18n);
Vue.config.productionTip = false;
var i18n = new VueI18n({
  locale: "ja",
  // 默认语言
  messages: {
    "zh-Hans": cnlang,
    "en": enlang,
    "ja": jalang,
    "fr": frlang,
    "ko": kolang,
    "zh-Hant": htlang,
    "de": delang,
    "es": eslang,
    "ru": rulang,
    "th": thlang,
    "pt": ptlang,
    "vnm": vnmlang,
    "idn": idn
  }
});
new Vue({
  router: router,
  i18n: i18n,
  // i18n
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");